import React from "react"
import { graphql, Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FeatureSide from '../../../components/feature-side'
import FeatureRelated from '../../../components/feature-related'

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="人を導くあなたへ。上司・先生・指導者が知るべき名言！"
        pagepath="/feature/08/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../../assets/images/feature/manager01.jpg"
							alt="人を導くあなたへ。上司・先生・指導者が知るべき名言！"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">人を導くあなたへ。上司・先生・指導者が知るべき名言！</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <div className="l-feature__paragraph">
                      <h2 className="l-feature__lead">
                        <span>上からの圧力、下からのプレッシャー。</span>
                        <span>人をまとめ、導くあなたの苦労は、先人たちも同じでした。</span>
                        <span>
                          人類の叡智たる彼らの言葉をご紹介します。<br />
                          きっと、明日のあなたの活力になるはずです！
                        </span>
                      </h2>
                    </div>

                    <div className="l-feature__paragraph">
                      <div className="l-feature__wrap">
                        <div className="l-feature__num">01.</div>
                        <div className="l-feature__quote">
                          {data.post1.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>偉大な音楽家、ベートーヴェンの人生には大きな障害がありました。</p>
                        <p>徐々に難聴が進行し、<span className="marker-b">４０歳を迎えるころには全ろう</span>となってしまいました。</p>
                        <p>音楽家として致命的な障害。しかし彼は諦めませんでした。</p>
                        <p>彼はその後も、精力的に活動を続け、歴史的な名作を残しました。</p>
                        <p>そんな彼の功績を見れば、この言葉の重みがより一層感じられます。</p>
                        <p><span className="marker-y">自身の不幸を嘆くことなく、自暴自棄になることなく、ただ耐え忍び活動を続けました。</span></p>
                        <p>そんなベートーヴェンが歴史に残るのは当然のことだと思います。</p>
                        <p>人生はどんな状況でも諦めてはいけない。そんな真理を教えてくれる言葉です。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">02.</div>
                        <div className="l-feature__quote">
                          {data.post2.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>2013年5月23日、<span className="marker-b">80歳で3度目のエベレスト登頂に成功</span>した三浦雄一郎。</p>
                        <p>アスリートとしてずっと第一線で活躍してきたイメージがありますが実は、<span className="f-l">65歳の時にメタボ</span>になってしまったことがあります。</p>
                        <p><span className="marker-b">身長164センチなのに体重88キロ、体脂肪率40を超えるまで肥えて</span>しまい、そこから改めて目標を持ち、80歳での偉業に繋がりました。</p>
                        <p><span className="f-l">しっかりとした準備があれば、あなたが何歳であっても目標を持つには遅すぎる事はありません。</span></p>
                        <p>そして勇気が必要と思われる冒険家であっても、準備は非常に重要な要因です。</p>
                        <p>この言葉を、冒険家の言葉とは捉えずに、<span className="marker-y">私たち日常生活に普遍的に通用する真理</span>だと理解しましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">03.</div>
                        <div className="l-feature__quote">
                          {data.post3.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>天才や開拓者と呼ばれる人たちは、<span className="marker-b">自分の力で偉大な発明をしたり、苦難を打開してきた</span>イメージがあります。</p>
                        <p>そんなイメージを真っ向から否定するような、本田のこの言葉。</p>
                        <p>天才であったとしても、<span className="f-l">ゼロから物事を作り出すことはできません。</span></p>
                        <p>この世の中にあるものはすべて<span className="f-l">既存の発明の組み合わせ</span>でしかありません。</p>
                        <p>そう考えれば、優れた発明家、事業化と言うのは<span className="marker-b">既存の物の組み合わせが上手い人</span>といえます。</p>
                        <p>まさに本田宗一郎がそうしたように私たちも新しいアイディアに出会いたかったら<span className="f-l">話を聞くこと</span>を積極的に行いたいですね。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">04.</div>
                        <div className="l-feature__quote">
                          {data.post4.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>皮肉とユーモアが利いた言葉ですね。</p>
                        <p>私たちがコントロールできるのは、<span className="f-l">自分自身でしかありません。</span></p>
                        <p>世界は私たちよりも強大で、私たちの力が及ばないところにあります。</p>
                        <p>不可能なことにチャレンジしてもそれは時間の無駄です。そんなことをしていると、<span className="marker-y">『自分』という、唯一変えられるものを変革する時間と労力を無駄に</span>してしまいます。</p>
                        <p><span className="f-l">自分が変えることができるのは自分だけ</span>だ。という事実を認識し、自分にフォーカスしましょう。</p>
                        <p></p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">05.</div>
                        <div className="l-feature__quote">
                          {data.post5.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>仏教ではカルマの法則を提唱します。</p>
                        <p>簡単に説明するならば、<span className="marker-b">人に良いことをすれば人から良いことをされるし、人に悪いことをすれば人から悪いことをされる</span>ということです。</p>
                        <p>この世の中で起こる現象は、すべて原因があり、その結果として私たちは良い事や悪い事を受けなければならないと考えます。</p>
                        <p>あなたを傷つける人に出会ったとしたら、仏教的解釈で言うならば、<span className="marker-b">あなたが過去に誰か他人を傷つけたことの結果が返ってきた</span>と考えるのです。</p>
                        <p>問題はその後です。あなたを傷つける人に出会った時に、あなたはどんな行動するでしょうか？</p>
                        <p>その答えの1つが、このダライ・ラマ14世の言葉です。</p>
                        <p><span className="f-l">あなたの忍耐と寛容を持って、傷つけてくる相手に接することができれば</span>、カルマはで終わります。</p>
                        <p>そしてあなたは<span className="f-l">精神的に強くなる</span>ことができます。</p>
                        <p>もし強くなったらあなたが、あなたを傷つけてくれる人に対して愛と優しさを持って接することができれば、それは新たな良いカルマを積むことになるのであなたは幸せになります。</p>
                        <p>カルマの法則を端的に表した、このダライ・ラマ14世の言葉。胸に刻みたいものですね。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">06.</div>
                        <div className="l-feature__quote">
                          {data.post6.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p><span className="marker-b">自分から謝ることのできる人は強い人</span>だと思います。</p>
                        <p>強い人とはどんな人でしょうか、それは<span className="marker-y">腕っ節が強かったり筋肉が大きい人のことを言うのでは無い</span>と思います。</p>
                        <p>やはり強いと言うのは、<span className="f-l">心の器</span>のことを言うのではないでしょうか。</p>
                        <p>あなたの心の器が広いならば、<span className="marker-b">堂々とあなたから謝ることができます。</span></p>
                        <p><span className="f-l">実るほど頭を垂れる稲穂かな</span>という言葉が日本にはありますが、まさに人間として精神的に豊かになった人は謙虚になれるのです。</p>
                        <p>謙虚に素直に謝ることができる人を見かけたら、<span className="marker-b">なんて精神的に強い人なんだ</span>と賞賛しましょう。</p>
                        <p>あなたがまだ、素直に謝ることができないなら、<span className="marker-b">いつか堂々と自分から謝ることができるように強くなる</span>と決意しましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">07.</div>
                        <div className="l-feature__quote">
                          {data.post7.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>私たちは様々な<span className="marker-y">道徳規範</span>によって生きています。</p>
                        <p>さらに現代においては、様々な価値基準がその上に乗っかっています。</p>
                        <p>例えば、<span className="marker-y">資本主義</span>。お金を持つことが幸せにつながる。お金を持っている人が偉い。そんな価値観を無意識に抱いていないでしょうか？</p>
                        <p>価値観が氾濫すると、<span className="f-l">私たちはとるべき行動を見失ってしまいます。</span></p>
                        <p>まずは、<span className="f-l">自分が本当に価値のあると思う価値観を定めること</span>が必要です。</p>
                        <p>それは仏教的価値観でもいいし、もちろんキリスト教的価値観でも良い、もしくは学校教育で習った道徳的観念でも構いません。</p>
                        <p>あなたが信じる価値観を確固たるものにしましょう。</p>
                        <p>あなたが信じる価値観が決まれば、<span className="f-l">とるべき正しい行動と言うのは自然と見えてきます。</span></p>
                        <p>あとは実行する勇気を持つだけです。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">08.</div>
                        <div className="l-feature__quote">
                          {data.post8.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>筑前国福岡藩初代藩主であった黒田長政。</p>
                        <p>リーダーとして素晴らしいスタンスを持っていたことがわかる言葉ですね。</p>
                        <p>立場が上になると、<span className="marker-y">どうしてもおごり高ぶってしまいます。</span></p>
                        <p>自分の意見が正しいと感じてしまい、<span className="marker-y">他者からのアドバイスを聞くことができなくなります。</span></p>
                        <p>あなただけの問題ではありません、<span className="marker-b">人間が普遍的に持つ問題</span>です。</p>
                        <p>だからこそ部下からの言葉を聞くと言う事は、<span className="marker-b">大変な苦痛</span>が伴います。</p>
                        <p>しかし、そこから逃げてしまうと、組織は崩壊し、愛すべき国を失ってしまうかもしれません。</p>
                        <p>黒田長政はそのことを知恵によって理解し、だからこそ部下にもこのように明言したのでしょう。</p>
                        <p>現代においてもこの考え方は通用します。<span className="f-l">リーダーたる人は肝に銘じておきたい言葉</span>ですね。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">09.</div>
                        <div className="l-feature__quote">
                          {data.post9.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ソクラテスの無知の知に通じる言葉ですね。まさに真理だと思います。</p>
                        <p><span className="f-l">本当に大切なことに気づき始めると、自分という枠の狭さに気付かされます。</span></p>
                        <p>そして、<span className="marker-b">人間の限界、人間の知性の限界</span>を感じます。</p>
                        <p>そのような智慧が生まれれば、<span className="f-l">謙虚にならざるを得ません。</span></p>
                        <p>賢者とは、知識がたくさんある人のことをいうのではありません。</p>
                        <p><span className="f-l">知識を行動に変え、自分と他者を幸福にすることが出来る人</span>のことを言います。</p>
                        <p>そのことに気付けるように、毎日を生きましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">10.</div>
                        <div className="l-feature__quote">
                          {data.post10.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p><span className="f-l">馬を水飲み場に連れて行くことはできても、馬に水を飲ませることはできない。</span></p>
                        <p>ということわざがあります。</p>
                        <p>人から強制されてやらされた事は、<span className="marker-y">興味が生まれません。</span></p>
                        <p>そこに教育のジレンマがありますね。</p>
                        <p>教育者たる人は、<span className="marker-b">人々にこれは面白いことなんだ</span>と紹介することぐらいしかできないのかもしれませんね。</p>
                        <p>トーマスエジソンを始め、<span className="f-l">独学で自分の境地を切り開いていった天才たち</span>がいます。</p>
                        <p>彼らは、<span className="f-l">自分で興味を開拓し、追求していた人</span>でした。</p>
                        <p>そうなれば彼らの一人旅。<span className="marker-b">教育者はその旅の準備を手伝ってあげる</span>ことしかできません。</p>
                        <p>親、教師、友人、兄弟。様々な立場から人を指導することが生まれると思いますが、この原則を覚えておきたいですね。</p>
                      </div>

                    </div>
                  </div>
                  <div className="l-feature__trunk">
                    <FeatureRelated />
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    post1: allContentfulBlogPost(
      filter: {id: {eq: "a10bb370-b7cc-540d-be9d-7308c4bf1d9f"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post2: allContentfulBlogPost(
      filter: {id: {eq: "023e363b-aa11-5eff-8787-1f16be772a33"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post3: allContentfulBlogPost(
      filter: {id: {eq: "c2b7d67d-a0c3-53d1-9531-fc480068fd43"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post4: allContentfulBlogPost(
      filter: {id: {eq: "0207bb6d-9605-5b21-aae5-dfb80e3ca65e"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post5: allContentfulBlogPost(
      filter: {id: {eq: "2096de00-b675-5b53-8fa8-563ffa793823"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post6: allContentfulBlogPost(
      filter: {id: {eq: "14e232cf-40b7-5458-bfe4-f464e83a965e"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post7: allContentfulBlogPost(
      filter: {id: {eq: "74732112-0f69-5ba4-ade5-3f850868c12d"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post8: allContentfulBlogPost(
      filter: {id: {eq: "482c9fa1-2eb6-560e-894b-ed0b7ea21676"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post9: allContentfulBlogPost(
      filter: {id: {eq: "42dfc2b8-48e7-5b12-999e-b7a7b8d6cd55"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post10: allContentfulBlogPost(
      filter: {id: {eq: "b3b78ae6-e7de-50d8-8651-84a7c7f569aa"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default Home